<template>
  <div>
    <div class="stats-graph">
      <div class="chart-row line-graphs-row">
        <overview-shipment-graph
          chart-class="overview-shipment-chart"/>
      </div>
      <div
        v-if="stat !== 'internal'"
        class="chart-row pie-chart-row with-border-top">
        <component
          :is="chartComponents[0].component"
          v-bind="chartComponents[0].props"/>

        <component
          v-loading="$actions.pending('company/overview/fetchTopCountriesOfOrigin') || $actions.pending('company/overview/fetchTopPorts')"
          :is="chartComponents[1].component"
          v-bind="chartComponents[1].props" />

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OverviewShipmentGraph from '~/components/company/overview/OverviewShipmentGraph'
import TopCompaniesChart from '~/components/company/overview/TopCompaniesChart'
import TopPortsChart from '~/components/company/overview/TopPortsChart'
import FormattedPieChart from '~/components/common/FormattedPieChart'
import CompanyInfoNavigation from '~/components/company/CompanyInfoNavigation'

export default {
  name: 'OverviewPage',
  scrollToTop: true,
  components: {
    CompanyInfoNavigation,
    OverviewShipmentGraph,
    TopCompaniesChart,
    TopPortsChart,
    FormattedPieChart
  },
  computed: {
    ...mapState(['isImporter']),
    ...mapState('company', ['overviewTopTradersCount']),
    ...mapState('company/overview', [
      'totals',
      'overviewChartsTopPorts',
      'overviewChartsTopCountriesOfOrigin',
      'overviewChartsTopTraders',
      'stat'
    ]),
    /**
     * Returns the Pie charts to be used depending on the company type
     */
    chartComponents() {
      const options = {
        consignee: [
          {
            component: FormattedPieChart,
            props: {
              title: `Top Suppliers`,
              data: this.overviewChartsTopTraders.shippers,
              fieldName: 'Suppliers',
              companyType: 'consignees'
            }
          },
          {
            component: FormattedPieChart,
            props: {
              title: `Top Countries`,
              data: this.overviewChartsTopCountriesOfOrigin,
              fieldName: 'Countries'
            }
          }
        ],
        shipper: [
          {
            component: FormattedPieChart,
            props: {
              title: `Top Importers`,
              data: this.overviewChartsTopTraders.consignees,
              fieldName: 'Importers',
              companyType: 'consignees'
            }
          },
          {
            component: FormattedPieChart,
            props: {
              title: `Top Ports`,
              data: this.overviewChartsTopPorts.destinations,
              fieldName: 'destinations'
            }
          }
        ],
        internal: [
          {
            component: FormattedPieChart,
            props: {
              title: `Top Ports`,
              data: this.overviewChartsTopPorts,
              fieldName: 'Destinations'
            }
          },
          {
            component: FormattedPieChart,
            props: {
              title: `Top Countries`,
              data: this.overviewChartsTopCountriesOfOrigin,
              fieldName: 'Countries'
            }
          }
        ]
      }

      if (this.stat !== 'all') {
        return options[this.stat]
      }

      const { consignee, shipper } = this.overviewTopTradersCount
      // As shipper uses consignees data and vice-versa
      if (consignee > shipper) {
        return options.consignee
      }
      return options.shipper
    }
  }
}
</script>
<style src="~/assets/scss/sections/company/overview.scss" lang="scss"/>
