<template>
  <div
    v-loading="loading"
    class="pie-item">
    <h2>{{ title }}</h2>

    <pie-chart
      :data="chartData.data"
      :legend="chartData.legend"
      pie-class="overview-pie-chart"/>
  </div>
</template>

<script>
import { sum, sumBy } from 'lodash'
import PieChart from '~/components/charts/PieChart'

/**
 * Reusable pie chart component with a title and legend
 * Shows a maximum of 5 slices plus a slice for the
 * total count of the rest of the data
 */
export default {
  name: 'FormattedPieChart',
  components: { PieChart },
  props: {
    /**
     * Title of the chart
     */
    title: {
      type: String,
      required: true
    },
    /**
     * Array of chart data containing the count and name fields
     * count - corresponding value of a data
     * name - data label
     */
    data: {
      type: Array,
      required: true
    },
    /**
     * Label to be used on the "others" slice
     */
    fieldName: {
      type: String,
      required: true
    },
    /**
     * If set to true, display the loading indicator on the component
     */
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Generate the chart data and legend
     */
    chartData() {
      const chartData = []
      const legend = []
      const minimumLength = this.data.length > 6 ? 5 : 6
      const length = Math.min(this.data.length, minimumLength)

      for (let i = 0; i < length; i++) {
        chartData.push(this.data[i].count)
        legend.push({ label: this.data[i].name, link: this.data[i].link || '' })
      }

      // Add a slice for the rest of the data, labeled "Others"
      if (this.data.length > chartData.length) {
        const othersCount = sumBy(this.data, 'count') - sum(chartData)
        chartData.push(othersCount)
        legend.push({
          label: `Other ${this.fieldName}`
        })
      }

      return {
        data: chartData,
        legend
      }
    }
  }
}
</script>
