<template>
  <div
    v-loading="$actions.pending('company/fetchOverviewData')"
    class="line-chart overview-shipments-chart">

    <div
      v-if="showHeader"
      class="overview-shipments-chart__header">
      <h2>Total shipments</h2>
      <span class="total-count">Shipments: <tween-number :value="Math.trunc(shipmentCount)"/></span>
    </div>

    <overview-shipment-chart
      :display-empty="true"
      :stat="stat"
      :data="datasets"
      :show-loader="false"
      :class="chartClass" />

    <div class="graph-label">
      <div class="legend">
        <span
          v-for="(key, index) in legendElements"
          :key="key"
          :style="{
            cursor: 'pointer',
            'text-decoration': visibleDataset.includes(key) ? 'none' : 'line-through'
          }"
          class="legend-item"
          @click="toggleDataset(key)" >
          <label
            :style="{ 'background-color': colors[index] }"
            class="circle"/>
          {{ getLegendLabel(key) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import TweenNumber from '~/components/common/TweenNumber'
import { mapState } from 'vuex'
import OverviewShipmentChart from '~/components/company/overview/OverviewShipmentChart'
import { chart } from '~/config/config'

export default {
  name: 'OverviewShipmentGraph',
  components: {
    TweenNumber,
    OverviewShipmentChart
  },
  props: {
    /**
     * Shows the Chart header
     */
    showHeader: {
      type: Boolean,
      default: true
    },
    /**
     * Defines the chart class to be used in the line graph
     */
    chartClass: {
      type: String,
      default: ''
    },
    /**
     * Displays all the data including the incomplete and empty ones
     */
    displayEmpty: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visibleDataset: []
    }
  },
  computed: {
    ...mapState('company', ['overviewData']),
    ...mapState('company/overview', ['stat']),

    /**
     * Returns the Shipments Count
     */
    shipmentCount() {
      return this.overviewData[this.stat].totalCount
    },

    /**
     * Returns the labels of the graph based on company status:
     *  - consignee
     *  - shipper
     *  - internal
     */
    legendElements() {
      if (this.stat === 'all') {
        const statLabels = Object.keys(this.overviewData)
        const statKeys = []

        for (const key of statLabels) {
          if (key !== 'all' && this.overviewData[key].totalCount > 0) {
            statKeys.push(key)
          }
        }

        return statKeys
      }
      return [this.stat]
    },

    /**
     * Returns the Colors for the Line Graph
     */
    colors() {
      return chart.line.colors
    },
    datasets() {
      if (this.stat === 'all') {
        //Get all company stat to be used in the charts
        const statLabels = Object.keys(this.overviewData)
        const tradersCount = []

        //Loop through the company labels and returns the data including the indicator if the it is visible to the user or not
        for (const key of statLabels) {
          if (key !== 'all' && this.overviewData[key].totalCount > 0) {
            const { shipments } = this.overviewData[key]
            tradersCount.push({
              key: key,
              data: shipments.map(({ count }) => count),
              visible: this.visibleDataset.includes(key)
            })
          }
        }

        return tradersCount
      }
      return [
        {
          key: this.stat,
          data: this.overviewData[this.stat].shipments.map(
            ({ count }) => count
          ),
          visible: this.visibleDataset.includes(this.stat)
        }
      ]
    }
  },
  mounted() {
    this.resetVisibleDataset()
  },
  methods: {
    /**
     * Return the Chart legend labels
     */
    getLegendLabel(key) {
      const labels = {
        shipper: 'as Importer',
        consignee: 'as Supplier',
        internal: 'Internal Shipments'
      }

      return labels[key]
    },

    /**
     * Hide/show the data in the Line graph using by clicking the Chart Labels
     */
    toggleDataset(key) {
      const index = this.visibleDataset.indexOf(key)

      if (index === -1) this.visibleDataset.push(key)
      else this.visibleDataset.splice(index, 1)
    },

    /**
     * Reset the Data shown to the users upon clicking an item in the Graph
     */
    resetVisibleDataset() {
      this.visibleDataset = Object.keys(this.overviewData)
    }
  }
}
</script>
