<template>
  <div
    v-loading="showLoader && $actions.pending('company/fetchOverviewData')">
    <shipments-chart
      :stat="stat"
      :color="color"
      :data="data"
      :labels="labels"
      :tooltip-text="tooltipText"
      :interval="$store.state.company.overview.dateInterval"
      :display-empty="displayEmpty"
      :autoscale-axis="true"/>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ShipmentsChart from '~/components/charts/ShipmentsChart/ShipmentsChart'

export default {
  name: 'OverviewShipmentChart',
  components: {
    ShipmentsChart
  },
  props: {
    stat: {
      type: String,
      default: ''
    },
    showLoader: {
      type: Boolean,
      default: true
    },
    displayEmpty: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('company', ['overviewData']),
    labels() {
      return this.overviewData.shipper.shipments.map(
        ({ from, to }) => `${from} - ${to}`
      )
    },
    color() {
      if (this.stat === 'all') {
        return ''
      }
      return this.overviewData[this.stat].color
    },
    tooltipText() {
      switch (this.stat) {
        case 'shipper':
          return [
            {
              key: 'shipper',
              text: 'Imports'
            }
          ]
        case 'consignee':
          return [
            {
              key: 'consignee',
              text: 'Exports'
            }
          ]
        case 'all':
          return [
            {
              key: 'shipper',
              text: 'Shipments as Importer'
            },
            {
              key: 'consignee',
              text: 'Shipments as Supplier'
            },
            {
              key: 'internal',
              text: 'Internal Shipments'
            }
          ]
        case 'internal':
          return [
            {
              key: 'internal',
              text: 'Internal Shipments'
            }
          ]
      }
    }
  }
}
</script>

<style lang="scss">
.company-profile-content {
  .base-graph {
    .section-stats {
      a {
        &.active {
          color: $brand-text-color;

          h3 {
            font-weight: 400;
            font-size: $font-size-default;
          }
        }
      }
    }
  }
}
</style>
